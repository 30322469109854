import { render, staticRenderFns } from "./payment-options.vue?vue&type=template&id=5b502172&scoped=true"
import script from "./payment-options.vue?vue&type=script&lang=tsx"
export * from "./payment-options.vue?vue&type=script&lang=tsx"
import style0 from "./payment-options.vue?vue&type=style&index=0&id=5b502172&prod&scoped=true&lang=less"
import style1 from "./payment-options.vue?vue&type=style&index=1&id=5b502172&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b502172",
  null
  
)

export default component.exports