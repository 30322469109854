import { render, staticRenderFns } from "./AccountTags.vue?vue&type=template&id=3cc40260&scoped=true"
import script from "./AccountTags.vue?vue&type=script&lang=tsx"
export * from "./AccountTags.vue?vue&type=script&lang=tsx"
import style0 from "./AccountTags.vue?vue&type=style&index=0&id=3cc40260&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cc40260",
  null
  
)

export default component.exports