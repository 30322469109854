













































































































































































































































































































































import { validateFn } from "../helpers";
import { notifier } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import DropdownControl from "../components/DropdownControl.vue";
import { NotificationTypes } from "@/helpers/ApiHelper";
import timeZones from "@/static/timeZones.json";
import PhoneInput from "../components/PhoneInput.vue";
import USState from "../static/USstates.json";

declare const $: any;
declare const getRouteData: Function;
@Component({
  inheritAttrs: false,
  components: {
    PhoneInput,
    LaddaButton,
    DropdownControl
  }
})
export default class Admin extends TSXComponent<void> {
  currentRole = sessionStorage.getItem("userRole");
  saving: any = false;
  checkList = [];
  details: {
    USERID?: number;
  } = {};
  validateFn;
  notificationTypeIds: number[] = [];
  notificationTypes: any = [];
  $allowedFunctions: any;
  $userFunctions: any;
  timeZoneList: any = [];
  selectedTimeZone: number[] = [9];
  timeZoneObj:any = {};
  stateList: any = [];
  selectedState: string[] = [];
  stateValue: string = "";

  $refs!: {
    drodown: DropdownControl;
  };

  created() {
    for (const i in timeZones) {
      this.timeZoneList.push({
        ID: i,
        TEXT: `${timeZones[i].name} - ${timeZones[i].gmtAdjustment}`,
        data: timeZones[i]
      });
    }
    for (const i in USState) {
      this.stateList.push({
        ID: USState[i].Abbr,
        TEXT: USState[i].Abbr,
        data: USState[i]
      });
    }
    this.fetchData();
  }

  async fetchData() {
    var dataObj = {
      controller: "VARAdmin",
      FunctionName: "Details",
      subsystem: "VAR360"
    };
    try {
      this.saving = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "VARAdmin",
        FunctionName: "Details",
        subsystem: "VAR360"
      });

      if (response.data.STATUS) {
        this.saving = false;
        this.details = response.data.USERSDETAILS;
        this.selectedState = [response.data.USERSDETAILS.USTATE];
        this.stateValue = response.data.USERSDETAILS.USTATE;
        if (this.currentRole == "Reseller") {
          // ignore types based on allowed functions
          const ignoreTypes: number[] = [];
          if (!this.$allowedFunctions.includes(this.$userFunctions.Invoices)) {
            // ignore invoice paid type
            ignoreTypes.push(NotificationTypes.InvoicePaid);
          }
          if (!this.$allowedFunctions.includes(this.$userFunctions.Quotes)) {
            // ignore new pending quote type
            ignoreTypes.push(NotificationTypes.NewPendingQuotes);
          }
          if (!this.$allowedFunctions.includes(this.$userFunctions.Orders)) {
            // ignore new order type
            ignoreTypes.push(NotificationTypes.NewOrder);
          }

          let notificationTypes = (
            response.data.notificationTypes || []
          ).filter(item => !ignoreTypes.includes(item.TYPEID));

          this.notificationTypes = notificationTypes.map(item => ({
            ID: item.TYPEID,
            TEXT: item.TYPENAME
          }));
          this.notificationTypeIds = notificationTypes
            .filter(item => item.SELECTED)
            .map(item => item.TYPEID);

          let timeZoneID = response.data.TIMEZONEDETAILS.ID || 9;
          this.selectedTimeZone = [timeZoneID];
          this.timeZoneObj = this.findTimeZone(timeZoneID);
        }
      }
      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      // notifier.alert("Create status error: " + err.message);
      console.log(err.message);
    } finally {
      this.saving = false;
    }
  }

  validateBeforeSubmit(e) {
    this.$validator.validateAll().then(result => {
      //validate data input
      if (
        $("#ufname").val() == "" ||
        $("#ulname").val() == "" ||
        $("#uemail").val() == ""
      ) {
        $("#errorMsgLineQuote").html(
          "Please make sure all your Personal Information is filled out!"
        );
        result = false;
      } else {
        $("#errorMsgLineQuote").html("");
        result = true;
      }

      if ($("#upassword").val() != "") {
        if ($("#uconfirmPassword").val() == "") {
          $(".errorMsg.confirmPassword").text(
            "The confirm password Field is required"
          );
          result = false;
        } else {
          $(".errorMsg.confirmPassword").text("");
        }
        if ($("#Currentpassword").val() == "") {
          $(".errorMsg.password").text(
            "The current password Field is required"
          );
          result = false;
        } else {
          $(".errorMsg.password").text("");
        }
        if ($(".passResetRequirements .validation.fail").length) {
          result = false;
        }
      } else {
        $(".errorMsg.confirmPassword").text("");
      }

      // if($('#Currentpassword').val() != '') {
      // 	if(this.$root.details.UPASSWORD != $('#Currentpassword').val()) {
      // 		$('.errorMsg.password').text('current password is not valid');
      // 		result = false;
      // 	}
      // 	else {
      // 		$('.errorMsg.password').text('');
      // 	}
      // }
      if (this.$validator.errors.items.length) {
        result = false;
      }

      if (result) {
        e.preventDefault();
        this.saving = true;
        return this.submit();
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  validatePassword() {
    var pwd = $("#upassword").val();
    var repwd = $("#uconfirmPassword").val();

    if ($.trim(pwd).length > 0) {
      if ($.trim(pwd) == $.trim(repwd)) {
        $(".validationMatch")
          .removeClass("fail")
          .addClass("success");
      } else {
        if (!$(".validationMatch").hasClass("fail")) {
          $(".validationMatch")
            .addClass("fail")
            .removeClass("success");
        }
      }

      if ($.trim(pwd).match(/[A-Z]/)) {
        $(".validationUppercase")
          .removeClass("fail")
          .addClass("success");
      } else {
        if (!$(".validationUppercase").hasClass("fail")) {
          $(".validationUppercase")
            .addClass("fail")
            .removeClass("success");
        }
      }

      if ($.trim(pwd).length >= 8) {
        $(".validationLength")
          .removeClass("fail")
          .addClass("success");
      } else {
        if (!$(".validationLength").hasClass("fail")) {
          $(".validationLength")
            .addClass("fail")
            .removeClass("success");
        }
      }

      if ($.trim(pwd).match(/\d/)) {
        $(".validationNumber")
          .removeClass("fail")
          .addClass("success");
      } else {
        if (!$(".validationNumber").hasClass("fail")) {
          $(".validationNumber")
            .addClass("fail")
            .removeClass("success");
        }
      }
    } else {
      $(".passResetRequirements .validation")
        .removeClass("success")
        .addClass("fail");
    }
  }
  async submit() {
    // var self: any = this;
    // Time Zone Info
    const timeZoneDetails = {
      ID: this.selectedTimeZone.length ? this.selectedTimeZone.join() : 0,
      DATA: this.timeZoneObj
    }
    const dataObj = {
      controller: "VARAdmin",
      FunctionName: "update",
      subsystem: "VAR360",
      userId: this.details.USERID,
      fname: $("#ufname").val(),
      lname: $("#ulname").val(),
      email: $("#uemail").val(),
      currentPassword: $("#Currentpassword").val(),
      notificationTypeIds: this.notificationTypeIds.join(","),
      uPhone: this.details.UPHONE,
      uPhoneExt: this.details.UPHONEEXT,
      uAddress: this.details.UADDRESS,
      uCity: this.details.UCITY,
      uState: this.details.USTATE,
      uZip: this.details.UZIP,
      timeZoneDetails: timeZoneDetails
    };
    if ($("#upassword").val() != "") {
      dataObj["password"] = $("#upassword").val();
    }
    // let resultCategoryTrend = getRouteData(dataObj);
    // resultCategoryTrend.then(function(response, statusText, jqXHR) {
    //   self.detail = response.data;
    //   if (response.data.STATUS === 1) {
    //     //notifier.success(response.data.MESSAGE);
    //     $(".errorMsg.password").text("");
    //     self.saving = false;
    //   } else {
    //     self.saving = "error";
    //     if (
    //       typeof response.data.STATUSKEY != "undefined" &&
    //       response.data.STATUSKEY == "invalid_cur_pass"
    //     ) {
    //       $(".errorMsg.password").text(response.data.STATUSMESSAGE);
    //     } else {
    //       notifier.alert(response.data.STATUSMESSAGE);
    //     }
    //   }
    // });

    const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
    if (response.data.STATUS === 1) {
      $(".errorMsg.password").text("");
      this.saving = false;
    } else {
      this.saving = "error";
      if (
        typeof response.data.STATUSKEY != "undefined" &&
        response.data.STATUSKEY == "invalid_cur_pass"
      ) {
        $(".errorMsg.password").text(response.data.STATUSMESSAGE);
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    }
  }

  updateNotificationTypeIds(typeId) {
    const inList = this.notificationTypeIds.find(id => id == typeId);
    if (inList) {
      // uncheck
      this.notificationTypeIds = this.notificationTypeIds.filter(
        id => id != typeId
      );
    } else {
      // check
      this.notificationTypeIds.push(typeId);
    }
  }

  selectAllTypeIds() {
    this.notificationTypeIds = this.notificationTypes.map(item => item.ID);
  }

  resetAllTypeIds() {
    this.notificationTypeIds = [];
  }

  getTypeName(typeId) {
    const inList = this.notificationTypes.find(item => item.ID == typeId);
    return inList ? inList.TEXT : "";
  }

  updateTimeZone(selectedID) {
    this.selectedTimeZone = [selectedID];
    this.timeZoneObj = this.findTimeZone(selectedID);
  }

  findTimeZone(id) {
    return this.timeZoneList.find(item => item.ID == id).data;
  }

  getExt(e){
    let ext = 'US';
    if(e != undefined){
      ext = e.split(' ')[0]
    }
    if(ext === '+[object') {
      ext = 'US';
    }
    return ext;
  }

  countryChanged(obj){
    let countryCode = '';
    if (typeof obj.countryCode != 'undefined') {
      countryCode = obj.countryCode
    }
    if (typeof obj.countryCallingCode != 'undefined') {
      countryCode = countryCode + ' ' + '+' + obj.countryCallingCode;
    }
    this.details.UPHONEEXT = countryCode;
  }

  addUserState(selectedID) {
    this.selectedState = [selectedID];
    this.details.USTATE = selectedID;
    this.stateValue = selectedID;
  }

  close(){
    this.$refs.drodown.dropdownVisible = false;
  }
}
