

































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import EmailMultiselect from "../components/EmailMultiselect.vue";
import LaddaButton from "../components/LaddaButton.vue";
import { PaycheckResp } from "../models/PaycheckResp";
import { notifier, wait } from "../models/common";

declare const dataURL: string;
declare const $: any;
declare const getRouteData: Function;
interface Props {
  details?: any;
  contactEmail?: string;
  invoiceID: string;
}
@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    EmailMultiselect
  }
})
export default class InvoiceMail extends TSXComponent<void> {
  @Prop({ required: false, default: {} })
  details?: any;

  @Prop({ required: false, default: "" })
  contactEmail?: string;

  @Prop({ required: true, default: "" })
  invoiceID!: string;

  loading: any = false;
  $parent: any;
  $validator: any;
  emailList: any[] = [];
  invoiceEmail = [];
  selectedUserIds: number[] = [];
  selectedCC: number[] = [];
  currentRole = sessionStorage.getItem("userRole");
  pageData: PaycheckResp.Root = {
    CURRENTPAGE: 1,
    TOTALPAGES: 1,
    Users: [],
    ORDERS: [],
    EXCLUDEDORDERS: 0,
    CommissionStatements: []
  };
  $refs!: {
    modal: HTMLDivElement;
  };
  moreEmails: string | undefined = "";
  sendToMessage = "";
  ccTo = "";
  ccToMessage = "";
  selectedEmails: string[] = [];
  selectedCCEmails: string[] = [];
  iNotes = "";
  prevSentList = [];
  contractNumber = "";
  PO = "";
  invoiceTemplatesList: any = [];
  selectedTemplate = 0;
  showPo = "Yes";
  // isActive = "basic";
  advancedFilters = {
    priceRolledUp: true,
    hideIncludeItems: false,
    showPaymentLink: false,
    hideZeroPrice: false
  };
  remitEmailError = false;
  customTemplateDetails: any = {
    footerContent: "",
    //remit address
    companyName: "",
    streetAddress: "",
    cityState: "",
    remitEmail: "",
    companyURL: "",
    // information header
    shipAddress: false,
    customerPO: false,
    contractNumber: false,
    invoiceId: false,
    invDate: false,
    dueDate: false,
    invoiceTerms: false,
    billAddress: false,
    // data fields
    serialNumber: false,
    assetTag: false,
    trackingInfo: false,
    templateItemReorder: []
  };

  includeCSVAttachment: boolean = true

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async created() {
    await this.fetchdata();
    if (this.currentRole == "Reseller") {
      if (typeof this.details.orderParams != "undefined") {
        let contactEmail = this.details.orderParams.contactEmail || "";
        // this.moreEmails = contactEmail;
        // this.selectedUserIds = contactEmail.length
        //   ? contactEmail.split(",").map(id => parseInt(id))
        //   : [];
      }
      if (this.contactEmail != "") {
        this.moreEmails = this.contactEmail;
      }

      // init for selectedEmails
      if (this.selectedUserIds.length) {
        this.selectedEmails = [];
        for (const id of this.selectedUserIds) {
          const inList: any = this.emailList.find(
            (item: any) => item.USERID == id
          );
          if (inList) {
            this.selectedEmails.push(inList.UEMAIL);
          }
        }
      }
    }
  }
  async fetchdata() {
    this.loading = true;
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Invoices",
      FunctionName: "MailList",
      invoiceID: this.invoiceID,
      getInvoiceDetails: this.currentRole == "Reseller" ? true : false
    });
    this.emailList = response.data.EMAILLIST.filter((email) => email.UEMAIL);
    if (response.data.contactmail && response.data.contactmail != "") {
      // this.contactEmail = response.data.contactmail;
      this.selectedUserIds = response.data.contactmail
        .split(",")
        .map(id => parseInt(id)).filter((id) => this.emailList.findIndex(email => email.USERID === id) >= 0);
    }
    const sentList = response.data.PrevSentList || "";
    if (sentList != "") {
      this.prevSentList = sentList.split(",").map((email, index) => ({
        USERID: index + 1,
        UEMAIL: email
      }));
    }

    this.iNotes = response.data.iNotes || "";
    if (this.iNotes == "Invoice Auto Generated") {
      this.iNotes = "";
    }

    // this.contractNumber = response.data.contractNumber || "";
    if (
      typeof response.data.CUSTOMDATACONTRACTNUMBER != "undefined" &&
      response.data.CUSTOMDATACONTRACTNUMBER != ""
    ) {
      this.contractNumber = response.data.CUSTOMDATACONTRACTNUMBER;
    } else {
      this.contractNumber = response.data.contractNumber || "";
    }
    this.PO = response.data.PO || "";

    // if (typeof this.details.TEMPLATES != "undefined") {
    //   this.invoiceTemplatesList = this.details.TEMPLATES.filter(
    //     (item: any) => item.TEMPLATETYPE == 2
    //   );
    // } else {
    //   this.invoiceTemplatesList =
    //     typeof response.data.TEMPLATES != "undefined"
    //       ? response.data.TEMPLATES.filter(
    //           (item: any) => item.TEMPLATETYPE == 2
    //         )
    //       : [];
    // }

    this.invoiceTemplatesList = (response.data.TEMPLATES || []).filter(
      (item: any) => item.TEMPLATETYPE == 2
    );

    this.selectedTemplate = response.data.InvoiceTemplateId || 0;
    if(this.invoiceTemplatesList.length == 1) {
      this.selectedTemplate = this.invoiceTemplatesList[0].TEMPLATEID;
    }
    this.updateCustomTemplateDetails();
    this.loading = false;
  }
  async validateBeforeSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.$validator.validateAll().then(result => {
      if (result) {
        return this.submit();
      }
    });
  }
  async updateInvoiceMail(data) {
    this.invoiceEmail = data.emailList.UEMAIL;
  }

  async toggleSelectedUserId(userId: number, emailStr) {
    const index = this.selectedUserIds.findIndex(id => id === userId);
    if (index === -1) {
      this.selectedUserIds.push(userId);
      this.selectedEmails.push(emailStr);
      return;
    }

    this.selectedUserIds.splice(index, 1);
    this.selectedEmails.splice(index, 1);
  }

  async selectAllUsers() {
    this.selectedUserIds = this.emailList.map((u: any) => u.USERID);
    this.selectedEmails = this.emailList.map((u: any) => u.UEMAIL);
  }

  async resetUsers() {
    this.selectedUserIds = [];
    this.selectedEmails = [];
  }

  async toggleSelectedCC(toggleID: number, emailStr) {
    const index = this.selectedCC.findIndex(id => id === toggleID);
    if (index === -1) {
      this.selectedCC.push(toggleID);
      this.selectedCCEmails.push(emailStr);
      return;
    }

    this.selectedCC.splice(index, 1);
    this.selectedCCEmails.splice(index, 1);
  }

  async selectAllCC() {
    this.selectedCC = this.prevSentList.map((u: any) => u.USERID);
    this.selectedCCEmails = this.prevSentList.map((u: any) => u.UEMAIL);
  }

  async resetCC() {
    this.selectedCC = [];
    this.selectedCCEmails = [];
  }

  async addNewEmail(newEmail) {
    const req = {
      controller: "Invoices",
      FunctionName: "AddInvoiceMail",
      invoiceID: this.invoiceID,
      newEmailId: newEmail,
      contactType: "Invoices"
    };

    const response = await axios.post(dataURL + "?ReturnType=JSON", req);

    if (response.data.STATUS == 1) {
      notifier.success(response.data.MESSAGE);
      await this.fetchdata();
    } else {
      notifier.alert(response.data.MESSAGE);
    }
  }

  async addNewCCEmail(newCCEmail) {
    const req = {
      controller: "Invoices",
      FunctionName: "AddInvoiceMail",
      invoiceID: this.invoiceID,
      newCCEmailId: newCCEmail
    };

    const response = await axios.post(dataURL + "?ReturnType=JSON", req);

    if (response.data.STATUS == 1) {
      notifier.success(response.data.MESSAGE);
      await this.fetchdata();
    } else {
      notifier.alert(response.data.MESSAGE);
    }
  }

  async submit() {
    //validate additional emails
    var isValid = true;
    this.sendToMessage = "";
    var tmp: any = [];
    if (this.moreEmails && this.moreEmails != "") {
      var tmpEmails = this.moreEmails.split(",");
      for (var anEmail of tmpEmails) {
        anEmail = anEmail.trim();
        if (!tmp.includes(anEmail)) {
          tmp.push(anEmail);
        }
        if (!this.isEmail(anEmail)) {
          isValid = false;
          this.sendToMessage = "Invalid Email";
        }
      }
    }

    // validate ccTo
    this.ccToMessage = "";
    var tmpCC: any = [];
    if (this.ccTo != "") {
      var tmpCCEmails = this.ccTo.split(",");
      for (anEmail of tmpCCEmails) {
        anEmail = anEmail.trim();
        if (!tmpCC.includes(anEmail)) {
          tmpCC.push(anEmail);
        }
        if (!this.isEmail(anEmail)) {
          isValid = false;
          this.ccToMessage = "Invalid Email";
        }
      }
    }

    if (isValid) {
      var allSelectedEmails = this.selectedEmails.join(",");
      if (this.selectedEmails.length) {
        allSelectedEmails += ",";
      }
      if (tmp.length) {
        allSelectedEmails += tmp.join(",");
      }
      allSelectedEmails = allSelectedEmails
        .split(",")
        .filter(email => email != "")
        .join(",");

      // collect all cc emails
      let allCCEmails = this.selectedCCEmails.join(",");
      if (this.selectedCCEmails.length) {
        allCCEmails += ",";
      }
      if (tmpCC.length) {
        allCCEmails += tmpCC.join(",");
      }

      this.loading = true;
      var self = this;
      const dataObj: any = {
        controller: "Invoices",
        FunctionName: "InvoiceMail",
        invoiceID: this.invoiceID,
        userids: this.selectedUserIds.join(","),
        Content: "Detailed",
        ExportType: "PDF",
        selectedemails: allSelectedEmails,
        ccEmails: allCCEmails,
        iNotes: this.iNotes,
        showPO: this.showPo,
        PO: this.PO,
        contractNumber: this.contractNumber,
        selectedTemplate: this.selectedTemplate,
        priceRolledUp: this.advancedFilters.priceRolledUp ? 1 : 0,
        showPaymentLink: this.advancedFilters.showPaymentLink ? 1 : 0,
        hidePriceZero: this.advancedFilters.hideZeroPrice,
        hideIncludeItems: this.advancedFilters.hideIncludeItems,
        includeCSVAttachment: this.includeCSVAttachment,
        contactType: "Invoices"
      };
      if(this.builderTemplate) {
        dataObj.templateCustomDetails = this.customTemplateDetails;
      }

      const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
      if (response.data.STATUS == 1) {
        // reload data for parent page
        if (this.$parent.$route.name == "ViewOrder") {
          this.$parent.getOrdersdetails();
        } else if (this.$parent.$route.name == "InvoiceDetails") {
          this.$parent.getInvoiceDetails();
        }
        self.loading = false;
        notifier.success(response.data.MESSAGE);
      } else {
        notifier.alert(response.data.MESSAGE);
        self.loading = "error";
      }

      this.$emit("close");
    } else {
      notifier.alert("Invalid email format, please try again");
    }
  }

  isEmail(email) {
    var regex = /^[\w+-]+([.][\w+-]+)*@\w+([-.]\w+)*\.([\w-]{2,4})+$/;
    return regex.test(email);
  }

  checkSendToEmails() {
    this.sendToMessage = "";
  }

  get builderTemplate() {
    let ret = false;
    // check selected template
    if (this.selectedTemplate > 0) {
      const inList = this.invoiceTemplatesList.find(
        item => item.TEMPLATEID == this.selectedTemplate
      );
      if (inList && (inList.ISBUILDERTEMPLATE || 0)) {
        ret = true;
      }
    }

    return ret;
  }

  elementEditable(elementType, elementId = "") {
    if (!this.builderTemplate) return true;

    // is template builder
    let templateparams: any = {};
    if (this.selectedTemplate > 0) {
      const inList = this.invoiceTemplatesList.find(
        item => item.TEMPLATEID == this.selectedTemplate
      );
      if (inList) {
        templateparams = JSON.parse(inList.TEMPLATEPARAMS || "{}");
      }
    }

    const selectedElements = templateparams.templateItemReorder || [];
    if (!selectedElements.length) return false;

    let inList = selectedElements.find(item =>
      elementId
        ? elementId == (item.id || "")
        : elementType == (item.type || "")
    );

    if (!inList) return false;

    return inList.editable || false;
  }

  updateCustomTemplateDetails() {
    if (!this.builderTemplate) return {};

    let templateparams: any = {};
    if (this.selectedTemplate > 0) {
      const inList = this.invoiceTemplatesList.find(
        item => item.TEMPLATEID == this.selectedTemplate
      );
      if (inList) {
        templateparams = JSON.parse(inList.TEMPLATEPARAMS || "{}");
      }
    }

    this.customTemplateDetails = {
      footerContent: templateparams.footerContent || "",
      //remit address
      companyName: templateparams.companyName || "",
      streetAddress: templateparams.streetAddress || "",
      cityState: templateparams.cityState || "",
      remitEmail: templateparams.remitEmail || "",
      companyURL: templateparams.companyURL || "",
      // information header
      shipAddress: templateparams.shipAddress || false,
      customerPO: templateparams.customerPO || false,
      contractNumber: templateparams.contractNumber || false,
      invoiceId: templateparams.invoiceId || false,
      invDate: templateparams.invDate || false,
      dueDate: templateparams.dueDate || false,
      invoiceTerms: templateparams.invoiceTerms || false,
      billAddress: templateparams.billAddress || false,
      // data fields
      serialNumber: templateparams.serialNumber || false,
      assetTag: templateparams.assetTag || false,
      trackingInfo: templateparams.trackingInfo || false,
      templateItemReorder: templateparams.templateItemReorder || []
    };
  }
}
