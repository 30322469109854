




















import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";
import Lottie from 'vue-lottie';
import * as newspaperIcon from '@/assets/newspaper-icon.json';
import * as circleLoopIcon from '@/assets/circle-loop-icon.json';

export interface Item {}

interface Props {}

interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Lottie
  }
})
export default class Loader extends TSXComponent<Props, Events> {
  get lottieOptions() {
    const anim = JSON.parse(JSON.stringify(newspaperIcon.default || newspaperIcon));
    
    // Convert hex color #bfbfbf to RGB (191,191,191)
    const r = 0xbf / 255;
    const g = 0xbf / 255;
    const b = 0xbf / 255;

    // Recursively update all fill colors in the animation
    const updateColors = (obj: any) => {
      if (obj.ty === 'fl' && obj.c && obj.c.k) {
        obj.c.k = [r, g, b, 1];
      }
      Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === 'object') {
          updateColors(obj[key]);
        }
      });
    };

    updateColors(anim);
    
    return {
      animationData: anim,
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet'
      },
      defaultColor: '#bfbfbf'
    };
  }

  get smallLottieOptions() {
    const anim = JSON.parse(JSON.stringify(circleLoopIcon.default || circleLoopIcon));
    
    // Convert hex color #bfbfbf to RGB (191,191,191)
    const r = 0xbf / 255;
    const g = 0xbf / 255;
    const b = 0xbf / 255;

    // Recursively update all fill colors in the animation
    const updateColors = (obj: any) => {
      if (obj.ty === 'fl' && obj.c && obj.c.k) {
        obj.c.k = [r, g, b, 1];
      }
      if (obj.ty === 'st' && obj.c && obj.c.k) {
        obj.c.k = [r, g, b, 1];
      }
      Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === 'object') {
          updateColors(obj[key]);
        }
      });
    };

    updateColors(anim);
    
    return {
      animationData: anim,
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet'
      },
      defaultColor: '#bfbfbf'
    };
  }
}
