var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"store-details-page",class:{
    preventEdit: !_vm.$allowedFunctions.includes(_vm.$userFunctions.EditPortalsStores)
  },attrs:{"id":"container"}},[_c('add-header'),_c('div',{staticClass:"up d-flex h-auto justify-content-between",attrs:{"id":"page-header"}},[_c('div',{staticClass:"d-inline-flex align-items-center",attrs:{"id":"page-title-container"}},[_c('div',{staticClass:"d-flex align-items-center w-100"},[_c('span',{staticClass:"page-title left"},[_c('span',{attrs:{"id":"previousIcon"},on:{"click":function($event){return _vm.listPageRedirection()}}})]),_c('div',{staticClass:"user-org ms-5 ml-5 text-black"},[_vm._v(" "+_vm._s(_vm.accountInfo.ANAME)+" "),(_vm.accountInfo.ACCOUNTID)?_c('a',{attrs:{"href":'#/customers/' + _vm.accountInfo.ACCOUNTID}},[_c('img',{staticClass:"arrowRight",attrs:{"src":"images/icon_arrow_right.png"}})]):_vm._e()])]),_c('span',{staticClass:"page-title left mb-3",attrs:{"data-type":"portalsStores"}},[_vm._v(" "+_vm._s(_vm.storeInfo.ACCOUNTNAME)+" ")]),_c('span',{staticClass:"d-flex w-100 flex-wrap"},[_c('div',{staticClass:"systemInfo textOverflow detailBubble"},[_c('span',{staticClass:"mr-1"},[_vm._v("Domain:")]),(_vm.storeInfo.STOREURL)?_c('a',{attrs:{"target":"_blank","href":_vm.getFullURL(_vm.storeInfo.STOREURL)}},[_vm._v(" "+_vm._s(_vm.storeInfo.STOREURL)+" ")]):_c('span',{staticClass:"text-grey"},[_vm._v("N/A")])])])]),(_vm.$allowedFunctions.includes(_vm.$userFunctions.EditPortalsStores))?_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"buttonContainer btnMd m-0"},[_c('span',{staticClass:"actionsBtn btn teal actions"},[_vm._v("Actions")]),_c('ul',{staticClass:"menu"},[_c('div',[_c('router-link',{attrs:{"to":{
                name: 'StoreCreate',
                params: {
                  id: _vm.storeId
                }
              }}},[_c('li',{staticClass:"actionsBtnItem"},[_vm._v("Edit Store")])])],1)])])]):_vm._e()]),_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"d-flex align-items-center justify-content-center w-100 orderNavContainer storeNavContainer"},[_c('div',{staticClass:"mt-0",attrs:{"id":"orderNav"}},[_c('div',{class:{
          active: _vm.isActive === 'catalogs'
        },on:{"click":function($event){return _vm.toggleActive('catalogs')}}},[_vm._m(0)]),_c('div',{class:{ active: _vm.isActive === 'pricing' },on:{"click":function($event){return _vm.toggleActive('pricing')}}},[_vm._v(" Pricing "),_c('span',{staticClass:"menuCount"},[_vm._v(" 0 ")])]),_c('div',{class:{ active: _vm.isActive === 'users' },on:{"click":function($event){return _vm.toggleActive('users')}}},[_vm._v(" Users "),_c('span',{staticClass:"menuCount"},[_vm._v(" 0 ")])]),_c('div',{class:{ active: _vm.isActive === 'settings' },on:{"click":function($event){return _vm.toggleActive('settings')}}},[_vm._v(" Settings "),_c('span',{staticClass:"menuCount"},[_vm._v(" 0 ")])])])]),_c('div',{staticClass:"mt-0",attrs:{"id":"page-details"}},[(
        _vm.loading ||
        (_vm.$refs.settingComponentRef && _vm.$refs.settingComponentRef.loading)
      )?_c('Loader'):_vm._e(),(_vm.isActive == 'catalogs')?[_c('div',{staticClass:"up",attrs:{"id":"detail-header"}}),_c('div',{staticClass:"position-relative",attrs:{"id":"details-content"}},[(!_vm.loading)?_c('div',{attrs:{"id":"details-content-container"}},[_c('br'),_vm._v(" Catalogs ")]):_vm._e()])]:_vm._e(),(_vm.isActive == 'pricing')?[_c('div',{staticClass:"up",attrs:{"id":"detail-header"}}),_c('div',{staticClass:"position-relative",attrs:{"id":"details-content"}},[(!_vm.loading)?_c('div',{attrs:{"id":"details-content-container"}},[_c('br'),_vm._v(" Pricing ")]):_vm._e()])]:_vm._e(),(_vm.isActive == 'users')?[_c('div',{staticClass:"up",attrs:{"id":"detail-header"}}),_c('div',{staticClass:"position-relative",attrs:{"id":"details-content"}},[(!_vm.loading)?_c('div',{attrs:{"id":"details-content-container"}},[_c('br'),_vm._v(" Users ")]):_vm._e()])]:(_vm.isActive == 'settings')?[_vm._m(1),_c('div',{staticClass:"position-relative",attrs:{"id":"details-content"}},[_c('div',{class:[
            { 'details-setting-content': _vm.selectedSetting },
            _vm.selectedSetting ? ("ss-settings-" + _vm.selectedSetting) : ''
          ],attrs:{"id":"details-content-container"}},[(!_vm.selectedSetting)?_vm._l((_vm.settingsCards),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"detail-item row align-items-center detail-setting-item"},[_c('div',{staticClass:"col-2 ib textOverflow"},[_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.selectSetting(item)}}},[_vm._v(" "+_vm._s(item.SETTINGKEYWORD)+" ")])]),_c('div',{staticClass:"col-7 ib"},[_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.selectSetting(item)}}},[_vm._v(" "+_vm._s(item.SETTINGDESC == "." ? "" : item.SETTINGDESC)+" ")])]),_c('div',{staticClass:"col-3 ib"})])])}):[_c('div',[_c('span',{staticClass:"previous-ico",on:{"click":function($event){return _vm.selectSetting(null)}}}),(_vm.allowEdit)?_c('div',{staticClass:"buttonContainer save-setting-btn"},[_c('LaddaButton',{staticClass:"teal right general-save-btn",attrs:{"type":"button","label":"Save","loading":_vm.saving,"disabled":_vm.saving},on:{"click":function($event){return _vm.saveSettings()}}})],1):_vm._e()]),_c('div',{staticClass:"setting-title"},[_vm._v(" "+_vm._s(_vm.currentSettingItem.SETTINGKEYWORD)+" ")]),_c(_vm.settingComponent,{ref:"settingComponentRef",tag:"component"})],_c('div',{staticClass:"clearfix"})],2)])]:_vm._e()],2),_c('div',{staticClass:"clearfix"}),_c('add-footer',{attrs:{"gotoSearch":_vm.gotoSearch}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"buttonContainer w-100"},[_c('span',{staticClass:"d-flex align-items-center actionsBtn justify-content-center"},[_vm._v(" Catalogs "),_c('span',{staticClass:"menuCount"},[_vm._v(" 0 ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"up",attrs:{"id":"detail-header"}},[_c('span',{staticClass:"expand-full"}),_c('div',{staticClass:"col-2 list-tab left"},[_vm._v("Settings Name")]),_c('div',{staticClass:"col-7 list-tab left"},[_vm._v("Settings Detail")]),_c('div',{staticClass:"col-3 list-tab left"},[_vm._v("Settings Category")])])}]

export { render, staticRenderFns }