/* eslint-disable no-useless-escape */
export const currency_convert = (
  OLMSLanguage,
  currencyLocale,
  country_currency,
  number,
  currencySymbolSwitch
) => {
  let position = "Beginning";

  /*FIND POSITION OF CURRENCY SYMBOL*/
  var regex = /[^0-9\.,\s]/g;
  var match = $.trim(number).search(regex);

  // if (
  //   typeof $("#launch-manager").attr("account-currency") != "undefined" &&
  //   typeof $("#launch-manager").attr("account-currency-locale") != "undefined"
  // ) {
  //   var Accountcurrency = $("#launch-manager").attr("account-currency");
  // }
  // if (
  //   this.$parent.savedSettings.olmsCurrency &&
  //   this.$parent.savedSettings.olmsCurrencyLocale
  // ) {
  //   var Accountcurrency = this.$parent.savedSettings.olmsCurrency;
  // }
  var Accountcurrency = country_currency;

  if (match > 0) {
    position = "End";
  }
  /*FIND POSITION OF CURRENCY SYMBOL*/

  /*check for correct decimal places to display*/
  var decimalPlaces = 2;
  var zeroPlaces = [
    "BIF",
    "BYR",
    "CVE",
    "DJF",
    "GNF",
    "IQD",
    "IRR",
    "ISK",
    "JPY",
    "KMF",
    "KPW",
    "LAK",
    "LBP",
    "MMK",
    "MGA",
    "PYG",
    "RWF",
    "SLL",
    "STD",
    "VND",
    "VUV",
    "XAF"
  ];
  var threePlaces = ["BHD", "CLP", "JOD", "KWD", "LYD", "OMR", "TND"];

  if (zeroPlaces.indexOf(country_currency) != -1) {
    decimalPlaces = 0;
    $(".cents.smrtCurrencyFormat").html("");
  } else if (threePlaces.indexOf(country_currency) != -1) {
    decimalPlaces = 3;
  }
  /*check for correct decimal places to display*/

  if (
    currencyLocale == "en_US" &&
    country_currency == "USD" &&
    threePlaces.indexOf(Accountcurrency) != -1
  )
    decimalPlaces = 3;

  try {
    currencyLocale = currencyLocale.replace(/_/g, "-");

    if (number === "" || typeof number === "undefined") {
      number = 0;

      var newCurrency = new Intl.NumberFormat(currencyLocale, {
        style: "currency",
        currency: country_currency,
        minimumFractionDigits: decimalPlaces
      }).format(number);
      newCurrency = $.trim(
        newCurrency.replace(/\d+/g, "").replace(/[&\/\\#,+()~%.'":*?<>{}]/g, "")
      );
    } else {
      if (/\$/g.test(number)) {
        number = number.replace(/[^\d\.]/g, "");
      }
      var newCurrency = new Intl.NumberFormat(currencyLocale, {
        style: "currency",
        currency: country_currency,
        minimumFractionDigits: decimalPlaces
      }).format(number);
    }
  } catch (e) {
    currencyLocale = OLMSLanguage.replace(/_/g, "-");
    var newCurrency = new Intl.NumberFormat(currencyLocale, {
      style: "currency",
      currency: Accountcurrency,
      minimumFractionDigits: decimalPlaces
    }).format(number);
  }

  if (detectIE() == "IE") {
    if (country_currency == "HKD") {
      newCurrency = "HK$" + newCurrency.replace(/[^0-9\.\,]/g, "");
    }
  }
  if (country_currency == "RSD") {
    // if(number == 0)
    //   newCurrency =  "RSD";
    // else
    newCurrency =
      newCurrency
        .replace(/din./g, "")
        .replace(/\./g, "-")
        .replace(/\,/g, "&")
        .replace(/-/g, ",")
        .replace(/&/g, ".")
        .replace(/[^0-9\.\,]/g, "") + " RSD";
  }

  if (detectIE() == "IE" && country_currency == "HKD") {
    newCurrency = "HK$" + newCurrency.replace(/[^0-9\.\,]/g, "");
  }

  if (country_currency == "BDT") {
    newCurrency = newCurrency.replace(
      /BDT./g,
      $.parseHTML("&#2547;")[0].nodeValue + " "
    );
  }

  if (country_currency == "EGP") {
    newCurrency = newCurrency.replace(
      /EGP/g,
      $.parseHTML("E&#163;")[0].nodeValue + " "
    );
  }

  if (country_currency == "ZAR") {
    newCurrency = newCurrency.replace(
      /ZAR/g,
      $.parseHTML("R")[0].nodeValue + " "
    );
  }

  if (country_currency == "TWD") {
    newCurrency = "NT" + newCurrency;
  }

  if (country_currency == "SDG") {
    newCurrency = newCurrency.replace(
      /SDG/g,
      $.parseHTML("&#36;")[0].nodeValue + " "
    );
  }

  if (country_currency == "ISK") {
    newCurrency =
      $.trim(newCurrency.replace(/ISK/g, "").replace(/,/g, ".")) +
      $.parseHTML(" kr.")[0].nodeValue;
  }

  if (country_currency == "MAD") {
    newCurrency =
      $.trim(newCurrency.replace(/MAD/g, "")) + $.parseHTML(" DH")[0].nodeValue;
  }

  if (country_currency == "CAD" && currencyLocale == "fr-CA") {
    newCurrency =
      newCurrency.replace(/[^0-9\.\,\s]/g, "") +
      $.parseHTML("&#36;")[0].nodeValue;
  }

  if (currencySymbolSwitch == false) {
    newCurrency = newCurrency.replace(/[^0-9,.\s]/g, "");
  }

  var currObj = newCurrency.toString();

  if (
    currObj.charAt(currObj.length - 5) == "," ||
    currObj.charAt(currObj.length - 3) == "," ||
    currObj.charAt(currObj.length - 2) == ","
  )
    var centsSeparator = ",";
  else if (
    currObj.charAt(currObj.length - 3) == "." ||
    currObj.charAt(currObj.length - 2) == "."
  )
    var centsSeparator = ".";
  else var centsSeparator = "";

  return $.trim(
    checkNumberSystem(
      newCurrency,
      country_currency,
      position,
      centsSeparator,
      currencySymbolSwitch
    )
  );
};

export const checkNumberSystem = (
  convertedCurrency,
  OLMSCurrency,
  position,
  centsSeparator,
  currencySymbolSwitch
) => {
  var currencyCents = convertedCurrency.split(centsSeparator)[1];
  if (position != "End") {
    if (OLMSCurrency == "PEN" || OLMSCurrency == "PKR") {
      var currencySymbol = convertedCurrency.substring(0, 3);
    } else {
      var currencySymbol = convertedCurrency.charAt(0);
    }
  } else {
    var currencySymbol = convertedCurrency.charAt(convertedCurrency.length - 1);
  }

  if (currencySymbolSwitch == false) {
    currencySymbol = "";
  }

  if (OLMSCurrency == "ZAR") {
    if (currencySymbolSwitch == false) {
      return convertedCurrency.replace(",", ".").trim().replace(/\s/g, "");
    }

    return convertedCurrency
      .replace(",", ".")
      .replace(/R\s/, "R")
      .replace(/\s/g, ",");
  }

  if (
    convertedCurrency.length >= 8 &&
    (OLMSCurrency == "INR" ||
      OLMSCurrency == "BDT" ||
      OLMSCurrency == "NPR" ||
      OLMSCurrency == "PKR" ||
      OLMSCurrency == "PEN")
  ) {
    var str = convertedCurrency
      .split(".")[0]
      .replace(/,/g, "")
      .replace(/\D/g, "");
    var x = str.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    if (OLMSCurrency == "ZAR")
      var res = otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree;
    else
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

    return currencySymbol + res + centsSeparator + currencyCents;
  } else {
    return convertedCurrency;
  }
};

export const detectIE = () => {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");
  var trident = ua.indexOf("Trident/");
  var edge = ua.indexOf("Edge/");

  if (msie > 0 || trident > 0 || edge > 0) {
    return "IE";
  } else {
    return "otherBrowser";
  }
};

/*NEW -- Currency Conversion on save of price*/
export const updateCurrencytoUSD = (OLMSLanguage, OLMSCurrency, price) => {
  var threePlaces = ["BHD", "CLP", "JOD", "KWD", "LYD", "OMR", "TND"];
  // if (typeof $("#launch-manager").attr("account-currency") != "undefined") {
  //   OLMSCurrency = $("#launch-manager").attr("account-currency");
  // } else {
  //   OLMSCurrency = $(".order-subset-list").attr("data-currency");
  // }

  // Overwrite the OLMSCurrency for sub-store
  // if (
  //   $("#OLMSACVnumber").length &&
  //   $("#OLMSACVnumber").val() != 0 &&
  //   typeof $("#OLMSACVnumber").attr("data-currency") != "undefined" &&
  //   $("#OLMSACVnumber").attr("data-currency") != ""
  // ) {
  //   OLMSCurrency = $("#OLMSACVnumber").attr("data-currency");
  // }
  price = $.trim(price.toString().replace(/[^0-9.,]/g, ""));

  if (
    price.length > 2 &&
    (!$.isNumeric(price.substr(-2, 1)) ||
      !$.isNumeric(price.substr(-3, 1)) ||
      (!$.isNumeric(price.substr(-4, 1)) &&
        threePlaces.indexOf(OLMSCurrency) != -1)) &&
    price.substr(-3, 1) != "."
  ) {
    let price1 = price.replace(/[^0-9.]/g, "@");
    let price2 = price1.replace(/[^0-9@]/g, ",");
    let price3 = price2.replace(/[^0-9,]/g, ".");

    price = price3;
  }
  var updatePrice = currency_convert(
    OLMSLanguage,
    "en_US",
    "USD",
    price.toString().replace(/[^0-9.]/g, ""),
    false
  );

  return parseFloat(updatePrice.replace(/,/g, ""));
};
