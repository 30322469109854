import { render, staticRenderFns } from "./SecurityMultiSelect.vue?vue&type=template&id=aefea7c2&scoped=true"
import script from "./SecurityMultiSelect.vue?vue&type=script&lang=tsx"
export * from "./SecurityMultiSelect.vue?vue&type=script&lang=tsx"
import style0 from "./SecurityMultiSelect.vue?vue&type=style&index=0&id=aefea7c2&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aefea7c2",
  null
  
)

export default component.exports