
























































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import DelayRemove from "@/components/DelayRemove.vue";
import { currency_convert, updateCurrencytoUSD } from "@/helpers/smartstore";
import DropdownControl from "@/components/DropdownControl.vue";
import axios from "axios";
import { removeHTML } from "@/helpers/ApiHelper";
import { notifier } from "@/models/common";

interface Props {}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: { DropdownControl, DelayRemove },
  methods: {
    currency_convert
  }
})
export default class ShippingCosts extends TSXComponent<Props, Events> {
  $parent: any;
  OLMSLanguage = "";
  OLMSCurrency = "";
  addNewVisible = false;
  addNewErrMsg = "";
  costs: any = [];
  delayRemoveIds: number[] = [];
  deleting = false;
  saving = false;
  shippingName = "";
  thresholdStart = "";
  thresholdStartErr = false;
  thresholdEnd = "";
  thresholdEndErr = false;
  shippingRate = 1; // Percentage Rate
  shippingTaxable = 1; // Yes
  shippingAmount = "";
  editLinkId = 0;
  cashOnly = false;

  async created() {
    await this.$parent.getSavedSettings();
    const saved = this.$parent.savedSettings;
    this.costs = saved.AccountShippingRatesData || [];
    this.cashOnly = saved.shippingCashOnly == 1 ? true : false;

    for (const item of this.costs) {
      item.TAXABLE = item.TAXABLE == 1 ? 1 : 0;
      item.RATEVALUE_converted =
        item.RATETYPE == 1
          ? item.RATEVALUE
          : currency_convert(
              saved.olmsLanguage,
              saved.olmsCurrencyLocale,
              saved.olmsCurrency,
              item.RATEVALUE,
              false
            );
      item.THRESHSTART_converted = currency_convert(
        saved.olmsLanguage,
        saved.olmsCurrencyLocale,
        saved.olmsCurrency,
        item.OLMS_SHIPPINGRATE_THRESHOLDSTART,
        false
      );
      item.THRESHEND_converted = currency_convert(
        saved.olmsLanguage,
        saved.olmsCurrencyLocale,
        saved.olmsCurrency,
        item.OLMS_SHIPPINGRATE_THRESHOLDEND,
        false
      );
    }

    this.OLMSLanguage = saved.olmsLanguage;
    this.OLMSCurrency = saved.olmsCurrency;
  }

  updateDelayRemoveIds(item) {
    this.delayRemoveIds.push(item.OLMS_LINK_ACCOUNTSHIPPINGRATEID);
    this.delayRemoveIds = [...new Set(this.delayRemoveIds)];
  }

  async removeCost(item) {
    const linkId = item.OLMS_LINK_ACCOUNTSHIPPINGRATEID || 0;
    if (!linkId) return;

    try {
      this.deleting = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Stores",
        FunctionName: "SaveSettingCardContent",
        storeId: this.$parent.storeId,
        selectedSetting: this.$parent.selectedSetting,
        data: {
          ACT: "delShipRate",
          Id: `${linkId}`
        }
      });

      if (response.data.STATUS == 1) {
        // eslint-disable-next-line require-atomic-updates
        item.isDeleted = true;
        this.delayRemoveIds = this.delayRemoveIds.filter(id => id != linkId);
        if (!this.delayRemoveIds.length) {
          this.costs = this.costs.filter(t => !(t.isDeleted || false));
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.deleting = false;
    }
  }

  async toggleAddNew() {
    this.addNewVisible = !this.addNewVisible;
    if (this.addNewVisible) {
      this.editLinkId = 0;
      this.shippingName = "";
      this.thresholdStart = "";
      this.thresholdStartErr = false;
      this.thresholdEnd = "";
      this.thresholdEndErr = false;
      this.shippingAmount = "";
      this.addNewErrMsg = "";
      this.shippingRate = 1; // Percentage Rate
      this.shippingTaxable = 1; // Yes
    }
  }

  async saveCost(addNew = false, item: any = {}) {
    if (this.saving) return;
    let valid = await this.$validator.validateAll();
    if (!valid) return;

    const saved = this.$parent.savedSettings;
    let Id = 0;
    let name = "";
    let shipAmount = "";
    let shipRate = 1;
    let shipTaxable = 1;
    let ThresholdS: any = "";
    let ThresholdE: any = "";

    if (addNew) {
      // is add new case
      name = this.shippingName = removeHTML(this.shippingName);
      ThresholdS = this.thresholdStart;
      ThresholdE = this.thresholdEnd;
      shipRate = this.shippingRate;
      shipAmount = this.shippingAmount;
      shipTaxable = this.shippingTaxable;
    } else {
      // is update case
      Id = item.OLMS_LINK_ACCOUNTSHIPPINGRATEID || 0;
      name = item.OLMS_SHIPPINGRATE_CUSTOMNAME = removeHTML(
        item.OLMS_SHIPPINGRATE_CUSTOMNAME
      );
      ThresholdS = item.THRESHSTART_converted;
      ThresholdE = item.THRESHEND_converted;
      shipRate = item.RATETYPE;
      shipAmount = item.RATEVALUE_converted;
      shipTaxable = item.TAXABLE;
    }

    if (!ThresholdS) {
      ThresholdS = currency_convert(
        saved.olmsLanguage,
        saved.olmsCurrencyLocale,
        saved.olmsCurrency,
        0.0,
        false
      );
    }
    if (!ThresholdE) {
      ThresholdE = currency_convert(
        saved.olmsLanguage,
        saved.olmsCurrencyLocale,
        saved.olmsCurrency,
        99999999.0,
        false
      );
    }
    ThresholdS = updateCurrencytoUSD(
      this.OLMSLanguage,
      this.OLMSCurrency,
      ThresholdS
    );
    ThresholdE = updateCurrencytoUSD(
      this.OLMSLanguage,
      this.OLMSCurrency,
      ThresholdE
    );

    // validate
    // let valid = true;
    if (!(ThresholdS >= 0)) {
      notifier.alert("Invalid Threshold Start");
      valid = false;
    } else if (!(ThresholdE >= 0)) {
      notifier.alert("Invalid Threshold End");
      valid = false;
    } else if (ThresholdS > ThresholdE) {
      notifier.alert("Invalid Threshold");
      valid = false;
    }
    if (!valid) return;

    try {
      this.saving = true;
      const data = {
        ACT: "updateShipRate",
        Id: `${Id}`,
        name,
        shipAmount,
        shipRate: `${shipRate}`,
        shipTaxable: `${shipTaxable}`,
        thresholdS: `${ThresholdS}`,
        thresholdE: `${ThresholdE}`
      };

      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Stores",
        FunctionName: "SaveSettingCardContent",
        storeId: this.$parent.storeId,
        selectedSetting: this.$parent.selectedSetting,
        data
      });

      if (response.data.STATUS == 1) {
        const message = response.data.STATUSMESSAGE || "";
        if (message) {
          notifier.success(message);
        }

        const moreInfo = response.data.moreInfo || {};
        const ssStatus = moreInfo.Status || 0;

        if (addNew) {
          const newItem = {
            OLMS_LINK_ACCOUNTSHIPPINGRATEID: moreInfo.ShippingID || 0,
            OLMS_SHIPPINGRATE_CUSTOMNAME: name,
            THRESHSTART: ThresholdS,
            OLMS_SHIPPINGRATE_THRESHOLDSTART: ThresholdS,
            THRESHSTART_converted: currency_convert(
              saved.olmsLanguage,
              saved.olmsCurrencyLocale,
              saved.olmsCurrency,
              ThresholdS,
              false
            ),
            THRESHEND: ThresholdE,
            OLMS_SHIPPINGRATE_THRESHOLDEND: ThresholdE,
            THRESHEND_converted: currency_convert(
              saved.olmsLanguage,
              saved.olmsCurrencyLocale,
              saved.olmsCurrency,
              ThresholdE,
              false
            ),
            RATETYPE: shipRate,
            RATEVALUE: shipAmount,
            RATEVALUE_converted:
              shipRate == 1
                ? shipAmount
                : currency_convert(
                    saved.olmsLanguage,
                    saved.olmsCurrencyLocale,
                    saved.olmsCurrency,
                    shipAmount,
                    false
                  ),
            CURRATE: shipRate == 1 ? `${shipAmount}%` : `$${shipAmount}`,
            TAXABLE: shipTaxable
          };

          if (newItem.OLMS_LINK_ACCOUNTSHIPPINGRATEID) {
            this.addNewVisible = false;
            this.addNewErrMsg = "";
            this.thresholdStartErr = this.thresholdEndErr = false;
            this.costs.push(newItem);
          } else if (ssStatus != 1) {
            this.thresholdStartErr = this.thresholdEndErr = true;

            // show error message
            if (ssStatus == 2) {
              this.addNewErrMsg =
                "Threshold Error: Your Threshold start and/or Threshold end are overlapping another Freight Preset";
            } else if (ssStatus == 3) {
              this.addNewErrMsg =
                "Added freight thresholds confict with another stores existing freight thresholds";
            }
          }
        } else {
          /* eslint-disable require-atomic-updates */
          if (ssStatus == 1) {
            this.editLinkId = 0;
            item.thresholdStartErr = item.thresholdEndErr = false;
            item.errMsg = "";

            item.RATEVALUE = item.OLMS_SHIPPINGRATE_RATEFLAT =
              item.RATEVALUE_converted;
            item.CURRATE =
              item.RATETYPE == 1 ? `${item.RATEVALUE}%` : `$${item.RATEVALUE}`;
            item.THRESHSTART = `$${ThresholdS}`;
            item.OLMS_SHIPPINGRATE_THRESHOLDSTART = ThresholdS;
            item.THRESHSTART_converted = currency_convert(
              saved.olmsLanguage,
              saved.olmsCurrencyLocale,
              saved.olmsCurrency,
              ThresholdS,
              false
            );
            item.THRESHEND = `$${ThresholdE}`;
            item.OLMS_SHIPPINGRATE_THRESHOLDEND = ThresholdE;
            item.THRESHEND_converted = currency_convert(
              saved.olmsLanguage,
              saved.olmsCurrencyLocale,
              saved.olmsCurrency,
              ThresholdE,
              false
            );
          } else if (ssStatus != 1) {
            item.thresholdStartErr = item.thresholdEndErr = true;
            // show error message
            if (ssStatus == 2) {
              item.errMsg =
                "Threshold Error: Your Threshold start and/or Threshold end are overlapping another Freight Preset";
            } else if (ssStatus == 3) {
              item.errMsg =
                "Added freight thresholds confict with another stores existing freight thresholds";
            }
          }
          /* eslint-disable require-atomic-updates */
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.saving = false;
    }
  }

  showEditLine(item) {
    this.editLinkId = item.OLMS_LINK_ACCOUNTSHIPPINGRATEID;
    this.addNewVisible = false;
  }

  async cashOnlyChange() {
    try {
      this.saving = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Stores",
        FunctionName: "SaveSettingCardContent",
        storeId: this.$parent.storeId,
        selectedSetting: this.$parent.selectedSetting,
        data: {
          ACT: "UpdateShippingCashOnly",
          shippingCashOnly: this.cashOnly ? 1 : 0
        }
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.saving = false;
    }
  }
}
