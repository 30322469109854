import { render, staticRenderFns } from "./QuoteDetails.vue?vue&type=template&id=55d2980a&scoped=true"
import script from "./QuoteDetails.vue?vue&type=script&lang=tsx"
export * from "./QuoteDetails.vue?vue&type=script&lang=tsx"
import style0 from "./QuoteDetails.vue?vue&type=style&index=0&id=55d2980a&prod&scoped=true&lang=less"
import style1 from "./QuoteDetails.vue?vue&type=style&index=1&id=55d2980a&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55d2980a",
  null
  
)

export default component.exports