




































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import DropdownControl from "@/components/DropdownControl.vue";
import LaddaButton from "@/components/LaddaButton.vue";
import axios from "axios";
import { notifier } from "@/models/common";
import { removeHTML } from "@/helpers/ApiHelper";
import DelayRemove from "@/components/DelayRemove.vue";
import InfoTooltip from "@/components/InfoTooltip.vue";
import { currency_convert, updateCurrencytoUSD } from "@/helpers/smartstore";

interface Props {}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;
declare const Stripe: any;

@Component({
  inheritAttrs: true,
  components: { DropdownControl, LaddaButton, DelayRemove, InfoTooltip },
  methods: {
    currency_convert
  }
})
export default class PaymentOptions extends TSXComponent<Props, Events> {
  $parent: any;
  showPaymentClient = 0;
  showPaymentAdmin = 0;
  OLMSLanguage = "";
  OLMSCurrency = "";
  addedPayment: any = [];
  addNewVisible = false;
  selectedPaymentModuleId = 0;
  customName = "";
  thresholdStart = "";
  thresholdEnd = "";
  delayRemoveIds: number[] = [];
  editLinkId = 0;
  credsVisible = -1;
  getCreds: any = {};
  getCredInputs: any = [];
  savingCreds: boolean | string = false;
  saving = false;
  loading = false;
  deleting = false;

  async created() {
    await this.$parent.getSavedSettings();
    const saved = this.$parent.savedSettings;
    this.addedPayment = saved.AccountPaymentModulesData || [];
    for (const item of this.addedPayment) {
      item.OLMS_PAYMENTMODULE_THRESHOLDSTART_converted = currency_convert(
        saved.olmsLanguage,
        saved.olmsCurrencyLocale,
        saved.olmsCurrency,
        item.OLMS_PAYMENTMODULE_THRESHOLDSTART,
        false
      );
      item.OLMS_PAYMENTMODULE_THRESHOLDEND_converted = currency_convert(
        saved.olmsLanguage,
        saved.olmsCurrencyLocale,
        saved.olmsCurrency,
        item.OLMS_PAYMENTMODULE_THRESHOLDEND,
        false
      );
    }
    if (saved.paymentSettingsData) {
      if (saved.paymentSettingsData.showClientOn.indexOf("Selected") != -1) {
        this.showPaymentClient = 1;
      }
      if (saved.paymentSettingsData.showAdminOn.indexOf("Selected") != -1) {
        this.showPaymentAdmin = 1;
      }
    }

    this.OLMSLanguage = saved.olmsLanguage;
    this.OLMSCurrency = saved.olmsCurrency;
  }

  collectData() {
    return {
      status: 1,
      data: {
        OLMS_AccountDetails_SelectPaymentFrontON: `${this.showPaymentClient}`,
        OLMS_AccountDetails_SelectPaymentBackON: `${this.showPaymentAdmin}`
      }
    };
  }

  async toggleAddNew() {
    this.addNewVisible = !this.addNewVisible;
    if (this.addNewVisible) {
      this.customName = "";
      this.thresholdStart = "";
      this.thresholdEnd = "";
      this.editLinkId = 0;
      await this.$nextTick();
      if (this.paymentModulesList.length) {
        this.selectedPaymentModuleId = this.paymentModulesList[0].ID;
      }
    }
  }

  get paymentModulesList() {
    // current selected module ids
    const addedModuleIds = this.addedPayment.map(
      item => item.OLMS_PAYMENTMODULE_ID
    );

    // get a list of unadded modules
    const editedItem =
      this.addedPayment.find(
        item => item.OLMS_LINK_ACCOUNTPAYMENTID == (this.editLinkId || 0)
      ) || {};
    return (this.$parent.savedSettings.AllPaymentModulesData || [])
      .filter(
        item =>
          !addedModuleIds.includes(item.OLMS_PAYMENTMODULE_ID) ||
          item.OLMS_PAYMENTMODULE_ID == (editedItem.OLMS_PAYMENTMODULE_ID || 0)
      )
      .map(item => ({
        ID: item.OLMS_PAYMENTMODULE_ID,
        TEXT: item.OLMS_PAYMENTMODULE_NAME
      }));
  }

  get selectedPaymentModule() {
    return (
      (this.$parent.savedSettings.AllPaymentModulesData || []).find(
        item => item.OLMS_PAYMENTMODULE_ID === this.selectedPaymentModuleId
      ) || {}
    );
  }

  async savePaymentOption(addNew = false, item: any = {}) {
    if (this.saving) return;

    const saved = this.$parent.savedSettings;
    let Id = 0;
    let Name = "";
    let paymentId = 0;
    let ThresholdS: any = "";
    let ThresholdE: any = "";

    if (addNew) {
      // is add new case
      paymentId = this.selectedPaymentModuleId;
      Name = this.customName = removeHTML(this.customName);
      if (!Name) {
        Name = this.selectedPaymentModule.OLMS_PAYMENTMODULE_NAME;
      }
      ThresholdS = this.thresholdStart;
      ThresholdE = this.thresholdEnd;
    } else {
      // is update case
      Id = item.OLMS_LINK_ACCOUNTPAYMENTID;
      paymentId = item.OLMS_PAYMENTMODULE_ID;
      Name = item.OLMS_PAYMENTMODULE_CUSTOM = removeHTML(
        item.OLMS_PAYMENTMODULE_CUSTOM
      );
      if (!Name) {
        Name = item.OLMS_PAYMENTMODULE_NAME;
      }
      ThresholdS = item.OLMS_PAYMENTMODULE_THRESHOLDSTART_converted;
      ThresholdE = item.OLMS_PAYMENTMODULE_THRESHOLDEND_converted;
    }

    if (!ThresholdS) {
      ThresholdS = currency_convert(
        saved.olmsLanguage,
        saved.olmsCurrencyLocale,
        saved.olmsCurrency,
        0.0,
        false
      );
    }
    if (!ThresholdE) {
      ThresholdE = currency_convert(
        saved.olmsLanguage,
        saved.olmsCurrencyLocale,
        saved.olmsCurrency,
        99999999.0,
        false
      );
    }
    ThresholdS = updateCurrencytoUSD(
      this.OLMSLanguage,
      this.OLMSCurrency,
      ThresholdS
    );
    ThresholdE = updateCurrencytoUSD(
      this.OLMSLanguage,
      this.OLMSCurrency,
      ThresholdE
    );

    // validate
    let valid = true;
    if (!(ThresholdS >= 0)) {
      notifier.alert("Invalid Threshold Start");
      valid = false;
    } else if (!(ThresholdE >= 0)) {
      notifier.alert("Invalid Threshold End");
      valid = false;
    } else if (ThresholdS > ThresholdE) {
      notifier.alert("Invalid Threshold");
      valid = false;
    }
    if (!valid) return;

    try {
      this.saving = true;
      const data = {
        ACT: "updatePayment",
        Id: `${Id}`,
        Name,
        paymentId: `${paymentId}`,
        ThresholdS,
        ThresholdE,
        isRequired: addNew ? false : undefined
      };

      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Stores",
        FunctionName: "SaveSettingCardContent",
        storeId: this.$parent.storeId,
        selectedSetting: this.$parent.selectedSetting,
        data
      });

      if (response.data.STATUS == 1) {
        this.addNewVisible = false;
        const message = response.data.STATUSMESSAGE || "";
        if (message) {
          notifier.success(message);
        }

        const moreInfo = response.data.moreInfo || {};

        if (addNew) {
          const newItem = { ...this.selectedPaymentModule };
          newItem.OLMS_LINK_ACCOUNTPAYMENTID = moreInfo.PaymentModuleID || "";
          newItem.OLMS_PAYMENTMODULE_CUSTOM = Name;
          newItem.OLMS_PAYMENTMODULE_THRESHOLDSTART = ThresholdS;
          newItem.OLMS_PAYMENTMODULE_THRESHOLDSTART_converted =
            currency_convert(
              saved.olmsLanguage,
              saved.olmsCurrencyLocale,
              saved.olmsCurrency,
              ThresholdS,
              false
            );
          newItem.OLMS_PAYMENTMODULE_THRESHOLDEND = ThresholdE;
          newItem.OLMS_PAYMENTMODULE_THRESHOLDEND_converted = currency_convert(
            saved.olmsLanguage,
            saved.olmsCurrencyLocale,
            saved.olmsCurrency,
            ThresholdE,
            false
          );

          if (newItem.OLMS_LINK_ACCOUNTPAYMENTID) {
            this.addedPayment.push(newItem);
          }
        } else {
          this.editLinkId = 0;
          /* eslint-disable require-atomic-updates */
          item.OLMS_PAYMENTMODULE_CUSTOM = Name;
          item.OLMS_PAYMENTMODULE_THRESHOLDSTART_converted = currency_convert(
            saved.olmsLanguage,
            saved.olmsCurrencyLocale,
            saved.olmsCurrency,
            ThresholdS,
            false
          );
          item.OLMS_PAYMENTMODULE_THRESHOLDEND_converted = currency_convert(
            saved.olmsLanguage,
            saved.olmsCurrencyLocale,
            saved.olmsCurrency,
            ThresholdE,
            false
          );
          /* eslint-disable require-atomic-updates */
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.saving = false;
    }
  }

  async removePaymentOption(item) {
    const linkId = item.OLMS_LINK_ACCOUNTPAYMENTID || 0;
    if (!linkId) return;

    try {
      this.deleting = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Stores",
        FunctionName: "SaveSettingCardContent",
        storeId: this.$parent.storeId,
        selectedSetting: this.$parent.selectedSetting,
        data: {
          ACT: "delPaymentModule",
          Id: `${linkId}`
        }
      });

      if (response.data.STATUS == 1) {
        // eslint-disable-next-line require-atomic-updates
        item.isDeleted = true;
        this.delayRemoveIds = this.delayRemoveIds.filter(id => id != linkId);
        if (!this.delayRemoveIds.length) {
          this.addedPayment = this.addedPayment.filter(
            t => !(t.isDeleted || false)
          );
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.deleting = false;
    }
  }

  updateDelayRemoveIds(item) {
    this.delayRemoveIds.push(item.OLMS_LINK_ACCOUNTPAYMENTID);
    this.delayRemoveIds = [...new Set(this.delayRemoveIds)];
  }

  showEditLine(item) {
    this.editLinkId = item.OLMS_LINK_ACCOUNTPAYMENTID;
    this.addNewVisible = false;
    this.selectedPaymentModuleId = 0;
  }

  async showCredsTooltip(addNew = false, editedItem: any = {}) {
    if (this.credsVisible != -1) {
      // is opening
      if (
        (addNew && this.credsVisible == 0) ||
        (!addNew && this.credsVisible > 0)
      ) {
        return;
      }
    }

    let cred = 0;
    if (addNew) {
      this.credsVisible = 0;
      if (
        this.selectedPaymentModule.MODULECLASS.indexOf("creds") != -1 &&
        this.selectedPaymentModule.MODULECLASS.indexOf("valid") != -1
      ) {
        cred = this.selectedPaymentModule.OLMS_PAYMENTMODULE_ID;
      }
    } else {
      this.credsVisible = cred = editedItem.OLMS_PAYMENTMODULE_ID;
    }
    if (!cred) return;

    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Stores",
        FunctionName: "GetCreds",
        storeId: this.$parent.storeId,
        cred
      });
      if (response.data.STATUS == 1) {
        this.getCreds = response.data.getCreds || {};
        this.getCredInputs = response.data.getCredInputs || [];
        for (const item of this.getCredInputs) {
          item.value =
            this.getCreds[
              `OLMS_ACCOUNTCREDENTIALS_${item.VARSCD_CREDENTIALFIELDTYPE}`.toUpperCase()
            ] || "";

          item.type =
            item.VARSCD_CREDENTIALFIELDTYPE == "password" ? "password" : "text";
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  hideCredsTooltip() {
    this.credsVisible = -1;
  }

  get isAddingModuleHasCreds() {
    return (
      this.selectedPaymentModule.MODULECLASS.indexOf("creds") != -1 &&
      this.selectedPaymentModule.MODULECLASS.indexOf("valid") != -1
    );
  }

  async saveCreds(addNew = false, editedItem: any = {}) {
    const _self = this;

    // save stripe settings
    if (
      (this.credsVisible == 0 && this.selectedPaymentModuleId == 8) ||
      (this.credsVisible > 0 && editedItem.OLMS_PAYMENTMODULE_ID == 8)
    ) {
      let stripeSecret = "";
      let stripePublishable = "";
      for (const item of this.getCredInputs) {
        if (item.type == "password") {
          stripeSecret = item.value;
        } else if (item.type == "text") {
          stripePublishable = item.value;
        }
      }

      if (
        stripeSecret == "" ||
        stripePublishable == "" ||
        !stripeSecret.startsWith("sk_") ||
        !stripePublishable.startsWith("pk_")
      ) {
        if (
          stripeSecret &&
          stripePublishable &&
          (!stripeSecret.startsWith("sk_") ||
            !stripePublishable.startsWith("pk_"))
        ) {
          notifier.alert("Error Validating Credentials!");
        }
        return;
      }

      if (typeof Stripe == "undefined") {
        await $.getScript(
          "https://js.stripe.com/v2/",
          function (data, textStatus, jqxhr) {}
        ).fail(function (jqxhr, settings, exception) {
          // console.log("Stripe load failes");
        });
      }

      // handle response
      const stripeResponseHandler = async function (status, response) {
        if (
          response.error.message.indexOf(
            "You must supply either a card, customer, PII data, bank account, or account legal entity to create a token."
          ) > -1
        ) {
          const credData = {};
          credData["username"] = "Stripe";
          credData["password"] = stripeSecret;
          credData["extra1"] = stripePublishable;
          credData["credID"] = 7;
          credData["accountId"] = _self.$parent.savedSettings.OLMSAccountID;
          credData["app"] = "SmartStores";

          /* eslint-disable require-atomic-updates */
          try {
            _self.savingCreds = true;
            const response = await axios.post(dataURL + "?ReturnType=JSON", {
              controller: "Stores",
              FunctionName: "SaveCreds",
              storeId: _self.$parent.storeId,
              data: credData
            });
            if (response.data.STATUS == 1) {
              _self.savingCreds = false;
              const message = response.data.STATUSMESSAGE || "";
              if (message) {
                notifier.success(message);
              }
              _self.credsVisible = -1;
            } else {
              _self.savingCreds = "error";
            }
          } catch (err) {
            _self.savingCreds = "error";
            console.log(err);
          }
          /* eslint-disable require-atomic-updates */
        } else {
          const message =
            response.error.message.indexOf("Invalid API Key provided:") > -1
              ? "Incorrect Publishable Key!"
              : "Error Validating Credentials!";
          notifier.alert(message);
        }
      };

      try {
        Stripe.setPublishableKey(stripePublishable);
        Stripe.card.createToken($("#CredEntryForm"), stripeResponseHandler);
      } catch (e) {
        // do nothing
      }
    }
  }
}
